<template>
  <button class="btn btn-primary mr-1" @click="downloadSample">
    <i class="fas fa-file-csv"></i> Download Sample
  </button>
</template>

<script>
import { apiDownload } from "../../services/api";
export default {
  name: "DownloadSample",
  methods: {
    downloadSample() {
      return fetch(this.$store.state.api + "download-sample", {
        method: "GET",
        headers: {
          Authorization: this.$store.state.token,
        },
      })
        .then((response) => response.blob())
        .then((blob) => {
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement("a");
          a.href = url;
          a.download = "sample-data.xlsx";
          document.body.appendChild(a);
          a.click();
          a.remove();
          return blob;
        })
        .catch((error) => {
          this.errors = error.response.data;
        });
    },
  },
};
</script>