<template>
  <div class="row collapse" id="form-import">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <h4>Import Master Payroll</h4>
          <div class="card-header-form">
            <!-- <download-sample /> -->
            <button
              class="btn btn-danger float-right"
              data-target="#form-import"
              data-toggle="collapse"
            >
              <i class="fas fa-times"></i> Close
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="card card-body">
            <div class="row">
              <!-- <div class="col-12">
                <div class="form-group">
                  <label for="">Venue</label>
                  <select class="form-control" v-model="form.venue_id" required>
                    <option value>--Select Venue--</option>
                    <option
                      v-for="venue in data.venues"
                      :key="venue.id"
                      :value="venue.value"
                      >{{ venue.text }}</option
                    >
                  </select>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="">UMK</label>
                  <input
                    type="number"
                    v-model="form.umk"
                    class="form-control"
                    required
                  />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="">Month</label>
                  <select class="form-control" v-model="form.month" required>
                    <option value>--Select Month--</option>
                    <option
                      v-for="i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]"
                      :key="i"
                      :value="i"
                    >
                      {{
                        new Date(
                          new Date().setMonth(i)
                        ).toLocaleString("default", { month: "long" })
                      }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="">Year</label>
                  <input
                    type="number"
                    v-model="form.year"
                    minlength="4"
                    maxlength="4"
                    class="form-control"
                    id=""
                  />
                </div>
              </div>
              <div class="col-3">
                <div class="form-group">
                  <label for="">BPJS</label>
                  <input
                    type="number"
                    class="form-control"
                    v-model="form.bpjs"
                  />
                </div>
              </div> -->
              <div class="col-12">
                <div class="form-group">
                  <label for="">File</label>
                  <input type="file" class="form-control" @change="inputFile" />
                  <span class="text-disabled"
                    >*) Only accept CSV & XLSX format</span
                  >
                </div>
              </div>
              <div class="col-12 text-right">
                <button class="btn btn-primary" @click="submit">Submit</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import swal from "sweetalert";
import { VenueRepository } from "../../repositories/RepositoryFactory";
import { apiPost } from "../../services/api";
import DownloadSample from "./DownloadSample.vue";

export default {
  name: "FormImport",
  components: {
    // DownloadSample,
  },
  data() {
    return {
      data: {
        venues: [],
      },
      form: {
        file: null,
      },
    };
  },
  mounted() {
    VenueRepository.getForSelect().then((res) => {
      this.data.venues = res.data.data;
    });
  },
  methods: {
    submit() {
      let formData = new FormData();
      Object.entries(this.form).forEach((e) => {
        formData.set(e[0], e[1]);
      });
      apiPost("payroll/import/slip", formData, {
        "Content-Type": "application/form-data",
      })
        .then((res) => {
          this.$emit("submit");
          this.form.file = null;
          console.log(res);
        })
        .catch((err) => {
          swal("Error!", err.response.data.message, "error");
        });
    },
    inputFile(event) {
      this.form.file = event.target.files[0];
    },
  },
};
</script>
